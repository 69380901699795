<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="개정 이력" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <!-- 개정명 -->
            <c-text
              name="revName"
              label="개정명"
              v-model="selectedRow.revName"
            ></c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <!-- 개정일 -->
            <c-datepicker
              name="revYmd"
              type="year"
              label="개정일"
              v-model="selectedRow.revYmd"
            ></c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <!-- 개정내용 -->
            <c-textarea
              name="revContent"
              label="개정내용"
              v-model="selectedRow.revContent"
            ></c-textarea>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <!-- 개정자 -->
            <c-field
              name="revUserName"
              type="dept_user"
              label="LBLREVUSER"
              v-model="selectedRow.revUserName"
            ></c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <c-textarea
              label="LBLREVREASON"
              :required="true"
              :editable="editable"
              name="revReason"
              v-model="selectedRow.revReason" />
          </div>
          <div class="col-12">
            <c-upload
              :editable="editable"
              label="개정 첨부파일">
            </c-upload>
          </div>
        </template>
      </c-card>
      
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'revision-info',
  props: {
    selectedRow: {
      type: Object,
      default: () => ({
        seq: 1,
        revName: '',
        revYmd: '',
        revContent: '',
        revUserName: '',
        revReason: '',
        plantNm: "",
        deptNm: "",
      }),
    },
    isCheck: {
      type: Boolean,
      default: function() {
        return false
      },
    },
  },
  data() {
    return {
      editable: true,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [],
      safFacilityItems: [],
      title: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
    },
  }
};
</script>
