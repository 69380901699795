var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "개정 이력" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                  },
                  [
                    _c("c-text", {
                      attrs: { name: "revName", label: "개정명" },
                      model: {
                        value: _vm.selectedRow.revName,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "revName", $$v)
                        },
                        expression: "selectedRow.revName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: { name: "revYmd", type: "year", label: "개정일" },
                      model: {
                        value: _vm.selectedRow.revYmd,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "revYmd", $$v)
                        },
                        expression: "selectedRow.revYmd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                  },
                  [
                    _c("c-textarea", {
                      attrs: { name: "revContent", label: "개정내용" },
                      model: {
                        value: _vm.selectedRow.revContent,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "revContent", $$v)
                        },
                        expression: "selectedRow.revContent",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-field", {
                      attrs: {
                        name: "revUserName",
                        type: "dept_user",
                        label: "LBLREVUSER",
                      },
                      model: {
                        value: _vm.selectedRow.revUserName,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "revUserName", $$v)
                        },
                        expression: "selectedRow.revUserName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        label: "LBLREVREASON",
                        required: true,
                        editable: _vm.editable,
                        name: "revReason",
                      },
                      model: {
                        value: _vm.selectedRow.revReason,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedRow, "revReason", $$v)
                        },
                        expression: "selectedRow.revReason",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("c-upload", {
                      attrs: { editable: _vm.editable, label: "개정 첨부파일" },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }